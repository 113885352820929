exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---packages-themes-gatsby-theme-flexiblocks-src-pages-404-jsx": () => import("./../../../../packages/themes/gatsby-theme-flexiblocks/src/pages/404.jsx" /* webpackChunkName: "component---packages-themes-gatsby-theme-flexiblocks-src-pages-404-jsx" */),
  "component---src-pages-ambalaj-hakkinda-bilinmesi-gereken-hersey-index-jsx": () => import("./../../../src/pages/ambalaj-hakkinda-bilinmesi-gereken-hersey/index.jsx" /* webpackChunkName: "component---src-pages-ambalaj-hakkinda-bilinmesi-gereken-hersey-index-jsx" */),
  "component---src-pages-ambalaj-kalite-yonetim-uyarlama-index-jsx": () => import("./../../../src/pages/ambalaj-kalite-yonetim-uyarlama/index.jsx" /* webpackChunkName: "component---src-pages-ambalaj-kalite-yonetim-uyarlama-index-jsx" */),
  "component---src-pages-ambalaj-kutu-maliyet-hesaplama-index-jsx": () => import("./../../../src/pages/ambalaj-kutu-maliyet-hesaplama/index.jsx" /* webpackChunkName: "component---src-pages-ambalaj-kutu-maliyet-hesaplama-index-jsx" */),
  "component---src-pages-ambalaj-modelleri-index-jsx": () => import("./../../../src/pages/ambalaj-modelleri/index.jsx" /* webpackChunkName: "component---src-pages-ambalaj-modelleri-index-jsx" */),
  "component---src-pages-ambalaj-modellerinde-hizli-arama-index-jsx": () => import("./../../../src/pages/ambalaj-modellerinde-hizli-arama/index.jsx" /* webpackChunkName: "component---src-pages-ambalaj-modellerinde-hizli-arama-index-jsx" */),
  "component---src-pages-ambalaj-web-favorilerim-index-jsx": () => import("./../../../src/pages/ambalaj-web/favorilerim/index.jsx" /* webpackChunkName: "component---src-pages-ambalaj-web-favorilerim-index-jsx" */),
  "component---src-pages-ambalaj-web-guvenli-odeme-index-jsx": () => import("./../../../src/pages/ambalaj-web/guvenli-odeme/index.jsx" /* webpackChunkName: "component---src-pages-ambalaj-web-guvenli-odeme-index-jsx" */),
  "component---src-pages-ambalaj-web-guvenli-odeme-mobil-index-jsx": () => import("./../../../src/pages/ambalaj-web/guvenli-odeme-mobil/index.jsx" /* webpackChunkName: "component---src-pages-ambalaj-web-guvenli-odeme-mobil-index-jsx" */),
  "component---src-pages-ambalaj-web-index-jsx": () => import("./../../../src/pages/ambalaj-web/index.jsx" /* webpackChunkName: "component---src-pages-ambalaj-web-index-jsx" */),
  "component---src-pages-ambalaj-web-indirmelerim-index-jsx": () => import("./../../../src/pages/ambalaj-web/indirmelerim/index.jsx" /* webpackChunkName: "component---src-pages-ambalaj-web-indirmelerim-index-jsx" */),
  "component---src-pages-ambalaj-web-is-emri-listesi-index-jsx": () => import("./../../../src/pages/ambalaj-web/is-emri-listesi/index.jsx" /* webpackChunkName: "component---src-pages-ambalaj-web-is-emri-listesi-index-jsx" */),
  "component---src-pages-ambalaj-web-lisans-paketleri-index-jsx": () => import("./../../../src/pages/ambalaj-web/lisans-paketleri/index.jsx" /* webpackChunkName: "component---src-pages-ambalaj-web-lisans-paketleri-index-jsx" */),
  "component---src-pages-ambalaj-web-musteri-teklifleri-index-jsx": () => import("./../../../src/pages/ambalaj-web/musteri-teklifleri/index.jsx" /* webpackChunkName: "component---src-pages-ambalaj-web-musteri-teklifleri-index-jsx" */),
  "component---src-pages-ambalaj-web-odeme-donus-index-jsx": () => import("./../../../src/pages/ambalaj-web/odeme-donus/index.jsx" /* webpackChunkName: "component---src-pages-ambalaj-web-odeme-donus-index-jsx" */),
  "component---src-pages-ambalaj-web-parametreler-index-jsx": () => import("./../../../src/pages/ambalaj-web/parametreler/index.jsx" /* webpackChunkName: "component---src-pages-ambalaj-web-parametreler-index-jsx" */),
  "component---src-pages-ambalaj-web-profilim-index-jsx": () => import("./../../../src/pages/ambalaj-web/profilim/index.jsx" /* webpackChunkName: "component---src-pages-ambalaj-web-profilim-index-jsx" */),
  "component---src-pages-ambalaj-web-siparisler-index-jsx": () => import("./../../../src/pages/ambalaj-web/siparisler/index.jsx" /* webpackChunkName: "component---src-pages-ambalaj-web-siparisler-index-jsx" */),
  "component---src-pages-baski-oncesi-calismalarda-nelere-dikkat-edilmeli-index-jsx": () => import("./../../../src/pages/baski-oncesi-calismalarda-nelere-dikkat-edilmeli/index.jsx" /* webpackChunkName: "component---src-pages-baski-oncesi-calismalarda-nelere-dikkat-edilmeli-index-jsx" */),
  "component---src-pages-bicak-izi-cizimi-ve-ambalaj-hazirligi-index-jsx": () => import("./../../../src/pages/bicak-izi-cizimi-ve-ambalaj-hazirligi/index.jsx" /* webpackChunkName: "component---src-pages-bicak-izi-cizimi-ve-ambalaj-hazirligi-index-jsx" */),
  "component---src-pages-bicak-izi-indir-index-jsx": () => import("./../../../src/pages/bicak-izi-indir/index.jsx" /* webpackChunkName: "component---src-pages-bicak-izi-indir-index-jsx" */),
  "component---src-pages-davetiye-index-jsx": () => import("./../../../src/pages/davetiye/index.jsx" /* webpackChunkName: "component---src-pages-davetiye-index-jsx" */),
  "component---src-pages-gizlilik-politikasi-kvkk-index-jsx": () => import("./../../../src/pages/gizlilik-politikasi-kvkk/index.jsx" /* webpackChunkName: "component---src-pages-gizlilik-politikasi-kvkk-index-jsx" */),
  "component---src-pages-hakkimizda-index-jsx": () => import("./../../../src/pages/hakkimizda/index.jsx" /* webpackChunkName: "component---src-pages-hakkimizda-index-jsx" */),
  "component---src-pages-hizli-kutu-tasarlama-kolay-erisim-index-jsx": () => import("./../../../src/pages/hizli-kutu-tasarlama-kolay-erisim/index.jsx" /* webpackChunkName: "component---src-pages-hizli-kutu-tasarlama-kolay-erisim-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-kutu-tasarimi-modelsec-index-jsx": () => import("./../../../src/pages/kutu-tasarimi-modelsec/index.jsx" /* webpackChunkName: "component---src-pages-kutu-tasarimi-modelsec-index-jsx" */),
  "component---src-pages-lisans-fiyatlari-index-jsx": () => import("./../../../src/pages/lisans-fiyatlari/index.jsx" /* webpackChunkName: "component---src-pages-lisans-fiyatlari-index-jsx" */),
  "component---src-pages-mesafeli-satis-sozlesmesi-index-jsx": () => import("./../../../src/pages/mesafeli-satis-sozlesmesi/index.jsx" /* webpackChunkName: "component---src-pages-mesafeli-satis-sozlesmesi-index-jsx" */),
  "component---src-pages-ms-no-index-jsx": () => import("./../../../src/pages/ms-no/index.jsx" /* webpackChunkName: "component---src-pages-ms-no-index-jsx" */),
  "component---src-pages-online-bicak-izi-en-boy-yukseklik-index-jsx": () => import("./../../../src/pages/online-bicak-izi-en-boy-yukseklik/index.jsx" /* webpackChunkName: "component---src-pages-online-bicak-izi-en-boy-yukseklik-index-jsx" */),
  "component---src-pages-satin-alma-index-jsx": () => import("./../../../src/pages/satin-alma/index.jsx" /* webpackChunkName: "component---src-pages-satin-alma-index-jsx" */),
  "component---src-pages-siparis-formu-index-jsx": () => import("./../../../src/pages/siparis-formu/index.jsx" /* webpackChunkName: "component---src-pages-siparis-formu-index-jsx" */),
  "component---src-pages-teslimat-iade-kosullari-index-jsx": () => import("./../../../src/pages/teslimat-iade-kosullari/index.jsx" /* webpackChunkName: "component---src-pages-teslimat-iade-kosullari-index-jsx" */),
  "component---src-pages-uretim-takip-index-jsx": () => import("./../../../src/pages/uretim-takip/index.jsx" /* webpackChunkName: "component---src-pages-uretim-takip-index-jsx" */),
  "component---src-pages-vectorel-pdf-die-cutting-templates-index-jsx": () => import("./../../../src/pages/vectorel-pdf-die-cutting-templates/index.jsx" /* webpackChunkName: "component---src-pages-vectorel-pdf-die-cutting-templates-index-jsx" */)
}

